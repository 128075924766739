import { useState } from "react";
import Quiz from "../components/Quiz";
import Login from "../components/Login";

function Index() {

  const [showQuiz, setShowQuiz] = useState(false);
  const [authInfo, setAuthInfo] = useState('');
  const [quizInfo, setQuizInfo] = useState<any | null>(null);

  const onLoginSuccess = (authInfo: any, quizInfo:any) => {
    setAuthInfo(authInfo);
    setQuizInfo(quizInfo)
    setShowQuiz(true);
  }

  return   <>
    {!showQuiz && <Login onLoginSuccess={onLoginSuccess} />}
    {showQuiz && <Quiz authInfo={authInfo} quizInfo={quizInfo} />}
  </>

}

export default Index;
