import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Button,
    ButtonProps
} from '@chakra-ui/react'
import { useRef } from 'react'

const ConfirmationModal = ({ triggerButtonText, confirmationHeader, confirmationBody, onConfirmation, ...rest }:
    { triggerButtonText: string, confirmationHeader: string, confirmationBody: string, onConfirmation: () => void } & ButtonProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<HTMLButtonElement>(null)

    const handleConfirmation = () => {
        onConfirmation();
        onClose();
    }
    return (
        <>
            <Button {...rest} onClick={onOpen}>
                {triggerButtonText}
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {confirmationHeader}
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            {confirmationBody}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='green' onClick={handleConfirmation} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default ConfirmationModal;