import { Box, Heading, Spinner, TableCaption, Text } from "@chakra-ui/react"
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'

const Result = ({ result, questions }: { result: any, questions: any[] }) => {
    return <>
        {!result && <Spinner color={'brand.900'} size={'xl'} />}

        {result && <Box p={30} minW={'500px'} height={'500px'}>
            <Heading mx={5} size={'sm'}>Thank you for participating in the recruitment process. We will review your answers and get back to you.</Heading>
            <TableContainer my={5}>

                <Table size={'md'} variant={'simple'} colorScheme="blue">
                    <Tbody>
                        <Tr>
                            <Td>Total Questions</Td>
                            <Td isNumeric>{questions.length}</Td>
                        </Tr>

                        <Tr>
                            <Td>Attempted</Td>
                            <Td isNumeric>{result.attempted}</Td>
                        </Tr>
                        <Tr>
                            <Td>Not Attempted</Td>
                            <Td isNumeric>{result.notAttempted}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>}
    </>
}

export default Result