import { AbsoluteCenter, Button, VStack, useDisclosure, Text, Box } from "@chakra-ui/react";
import BlockUI from "../components/BlockUI";
import HolUnlock from "../components/HolUnlock";
import { useEffect, useRef, useState } from "react";

const HOL = () => {

    const { isOpen: blocking, onOpen, onClose } = useDisclosure();
    const [startedHol, setStartedHol] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {

        const messageHandler = (e: any) => {
            if (e.data.language) {
                if (!document.fullscreenElement) {
                    onOpen();
                }

                sessionStorage.setItem("ais-ras-hol", JSON.stringify(e.data))
            }
        };

        window.addEventListener("message", messageHandler)


      
        return () => window.removeEventListener("message", messageHandler)
    }, [])

    const unLockHandler = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        }
        onClose();
    }

    useEffect(()=>{
        const existingCode:any = JSON.parse(sessionStorage.getItem("ais-ras-hol")||"{}")
        if (startedHol && existingCode) {
            setTimeout(() => {
                iframeRef.current?.contentWindow?.postMessage({
                    eventType: 'populateCode',
                    language: existingCode.language,
                    files: existingCode.files
                }, "*");
            }, 3000);
           
        }
    },[startedHol])

    const startHol = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        }

        setStartedHol(true);
    }

    return <>

        {!startedHol && <Box p={50}>
            <AbsoluteCenter axis={'horizontal'}>
                <VStack>
                    <Text fontSize={18}>You have 60 minutes to write the program. Show your code to evaluator before leaving from system.</Text>
                    <Text fontWeight={'500'} fontStyle={'italic'}>Note: When HOL starts browser enters to full screen mode. If you exit from full screen, system automatically get locked.</Text>
                    <Button onClick={startHol} colorScheme="green">Start HOL</Button>
                </VStack>

            </AbsoluteCenter>
        </Box>
        }
        {startedHol && <>
            {
                blocking && <BlockUI>
                    <HolUnlock onUnlock={unLockHandler}></HolUnlock>
                </BlockUI>
            }
            <iframe ref={iframeRef}
                frameBorder="0"
                height="450px"
                src="https://onecompiler.com/embed/python?hideNew=true&hideNewFileOption=true&disableCopyPaste=false&listenToEvents=true&codeChangeEvent=true"
                width="100%"
                style={{ height: "calc(100vh - 200px)" }}
            ></iframe>
        </>
        }
    </>
}

export default HOL;