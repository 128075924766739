
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, PropsWithChildren } from 'react';
import { authenticate } from "../lib/auth";

export default function RouteGuard({ children }: PropsWithChildren) {

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated) {
            authenticate(msalContext)
        }
    }, [isAuthenticated, msalContext]);

    return <AuthenticatedTemplate>
        {children}
    </AuthenticatedTemplate>;
}
