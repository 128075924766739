import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from "@chakra-ui/react";
import * as React from 'react';

const theme = extendTheme({
    colors: {
        brand: {
            100:"#f7f7f8",
            200: "#f7f7f8",
            700:"#e07102",
            800:"#125a97",
            900: "#1c2c57",
        },
    },
    fontSizes: {
        base: "16px",
        xs: "12px",
        sm: "14px",
        md: "16px"
    },
    styles: {
        global: {
            // styles for the `body`
            '::-webkit-scrollbar': {
                width: '10px',
            },
            '::-webkit-scrollbar-track': {
                bgColor: '#f1f1f1'
            },
            '::-webkit-scrollbar-thumb': {
                bgColor: '#888888',
            },
            '::-webkit-scrollbar-thumb:hover': {
                bgColor: '#555555'
            }
        }
    }
})

export function Providers({
    children
}: {
    children: React.ReactNode
}) {
    return (
        <ChakraProvider theme={theme}>
                {children}
        </ChakraProvider>
    )
}
