import { Heading, VStack, Input, HStack, Button } from "@chakra-ui/react";
import { useState } from "react";

const HolUnlock = ({onUnlock}:{onUnlock:any}) => {

    const [unlockCode, setUnlockCode] = useState("");

    const unlockHandler = () => {
        if (unlockCode === 'HolUnlock2024!!!') {
            onUnlock();
        }
    }
    return <VStack bg={'white'} borderRadius={'10px'} p={10}>
        <Heading size={'md'} >Your system locked due to exit from full screen. Check with administrator to unlock.</Heading>
        <HStack>
            <Input type="password" placeholder="Enter unlock code" onChange={(event: any) => setUnlockCode(event.target.value)}></Input>
            <Button onClick={unlockHandler} colorScheme="red">Unlock</Button>
        </HStack>
    </VStack>
}

export default HolUnlock;