import React, {  } from 'react';
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { Link } from '@chakra-ui/react';

const MarkdownViewer: React.FC<{ content: string }> = ({ content }) => {

  const newTheme = {
    a: (props: any) => {
      const { href, children } = props;
      return <Link href={href} color={'blue.500'} isExternal={true}>{children}</Link>;

    }
  };
  return (
    <ReactMarkdown components={{...ChakraUIRenderer(newTheme),  sub: 'sub',
    sup: 'sup'}}
      children={content.replaceAll('\n','<br>')}
      skipHtml remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    />
  );
}

export default MarkdownViewer;