
import { Outlet } from "react-router-dom";
import { Box, Flex, Heading, Text, Image, Center, Divider, Link, Spacer } from "@chakra-ui/react";

const Layout = () => {
    return (
        <Box bgColor={"brand.900"} h={'100vh'} w={'100vw'} color={'brand.200'}>
            <Box position={'sticky'} py={5} zIndex={1000} >
                <Flex direction={'row'} gap={3} mt='5px' pr={10}>
                    <Image src="/AIS-Mark-Light.svg" h={50} w={100}></Image>
                    <Heading size={'md'} pt={5} textAlign='center' color={'brand.600'} >Recruitment Assessment System</Heading>
                    <Spacer />
                    <Link href="/#/" fontWeight={500}>HOME</Link>
                    <Center height='25px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <Link href="/#/hol" fontWeight={500}>HOL</Link>
                    <Center height='25px'>
                        <Divider orientation='vertical' />
                    </Center>
                    <Link href="/#/report" fontWeight={500}>RESULTS</Link>
                </Flex>
            </Box>
            <Box minH={'calc(100vh - 175px)'} bgColor={'brand.100'} color={'black'}>
                <Outlet />
            </Box>
            <Box bgColor={"brand.900"} position={'sticky'} bottom={0} py={5} px={50} zIndex={1000} >
                <Center>
                    <Text textAlign='center' color={'brand.600'} >@2024 Applied Info Services. </Text>
                </Center>
            </Box>
        </Box>
    );
};

export default Layout;
