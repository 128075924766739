import { useEffect, useRef, useState } from "react";
import { AbsoluteCenter, Center, Box, Button, Checkbox, CheckboxGroup, HStack, Heading, Input, Radio, RadioGroup, Spacer, Spinner, Stack, Text, VStack, useToast } from "@chakra-ui/react";
import Result from "./Result";
import MarkdownViewer from "./MarkdownViewer";
import fetchWrapper from "../lib/fetch-wrapper";
import QuizTimer from "./QuizTimer";
import Instructions from "./Instructions";
import ConfirmationModal from "./ConfirmationModal";

const Quiz = ({ authInfo, quizInfo }: { authInfo: any, quizInfo: any }) => {
  const [questions, setQuestions] = useState<any[]>(quizInfo.questions)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<any | null>(null)
  const [answers, setAnswers] = useState<any[]>([]);
  const [result, setResult] = useState<any | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState<string | (string | number)[] | undefined>(undefined)
  const [startQuiz, setStartQuiz] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setQuestions(quizInfo.questions);
  }, [quizInfo.questions])
  
  const onAnwswerClick = async (id: string, answer: string | (string | number)[]) => {
    if (showFullScreen && !document.fullscreenElement) {
      await submitQuiz();
      return;
    }

    setCurrentQuestionAnswer(answer)

    setAnswers((prev: any[]) => {
      const index = answers.findIndex((x: any) => x.id === id)
      if (index >= 0) {
        prev[index].answer = answer;
      }
      else {
        prev.push({ id: id, answer: answer })
      }
      return prev
    })
  };

  const onClickPrevious = async () => {
    if (showFullScreen && !document.fullscreenElement) {
      await submitQuiz();
      return;
    }

    setCurrentQuestionAnswer(undefined)
    setCurrentQuestionIndex((prev) => prev - 1);
  }

  const onClickNext = async () => {
    setCurrentQuestionAnswer(undefined)
    if (currentQuestionIndex !== questions.length - 1) {

      if (showFullScreen && !document.fullscreenElement) {
        await submitQuiz();
        return;
      }

      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      await submitQuiz();
    }
  };

  const submitQuiz = async () => {
    setCurrentQuestion(null)
    setCurrentQuestionIndex(0);
    setShowResult(true);
    const fw = new fetchWrapper();
    const resultsResp = await fw.post(`/api/questions/${authInfo.testCode}/${authInfo.name}`, { ...authInfo, answers })
    if (resultsResp.status === 200) {
      const results = await await resultsResp.json()
      setResult(results)
      toggleFullscreen(false);
    } 
    else {
      toast({
        title: 'Error occurred',
        description: 'Something went worng. Try again...',
        status: "error",
        position: 'top',
        isClosable: true,
      })
      setShowResult(false);
    }

  }

  useEffect(() => {
    if (questions.length > 0) {
      setCurrentQuestion(questions[currentQuestionIndex])
    }
  }, [currentQuestionIndex])

  useEffect(() => {
    if (currentQuestion) {
      var current = answers.filter((x: any) => x.id == currentQuestion.id)[0]
      if (current) {
        setCurrentQuestionAnswer(current.answer)
      }
    }
  }, [currentQuestion])

  const ref = useRef(null);

  const toggleFullscreen = (fullScreen: boolean) => {
    setShowFullScreen(fullScreen)
    if (document.fullscreenElement && !fullScreen) {
      document.exitFullscreen();
    } else if (fullScreen && !document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };

  const handleStartTest = () => {
    toggleFullscreen(true);
    setStartQuiz(true);
  }

  return (

    <Box bgColor={'brand.200'} color={'black'} p={10} ref={ref}>

      <AbsoluteCenter axis={showResult ? 'both' : 'horizontal'}>

          {!startQuiz && <VStack>
            <Instructions name={authInfo.name} quizId={authInfo.testCode} numberOfQuestions={questions.length} description={quizInfo?.description} duration={quizInfo?.duration}></Instructions>
            <Center><Button colorScheme="green" onClick={handleStartTest}>Click to Start Test</Button></Center>
            <Text fontWeight={'500'} fontStyle={'italic'}>Note: When test starts browser enters to full screen mode. If you exit from full screen, system automatically submits your test.</Text>
          </VStack>}

          {startQuiz && <>

            {!showResult ? (
              <>
                {currentQuestion && <>
                  <QuizTimer quizTimeInMinutes={quizInfo?.duration} onTimeElapsed={submitQuiz}></QuizTimer>
                  <Box w={'calc(100vw - 200px)'} >
                    <Box minH={'calc(100vh - 300px)'}>
                      <HStack>
                        <Heading size={'sm'}>{currentQuestionIndex + 1}.</Heading>
                        <Box mt={2}>
                          <MarkdownViewer content={currentQuestion.question}></MarkdownViewer>
                        </Box>
                      </HStack>

                      {currentQuestion.type === "MCQ" &&
                        <Box m={3}>
                          <RadioGroup value={currentQuestionAnswer?.toString() || ''} onChange={(value) => onAnwswerClick(currentQuestion.id, value)}>
                            <Stack spacing={5}>
                              {currentQuestion.choices.map((choice: string) => (
                                <Radio value={choice} borderColor={'gray.600'} key={choice}>
                                  <div dangerouslySetInnerHTML={{ __html: choice }}></div>
                                </Radio>

                              ))}
                            </Stack>
                          </RadioGroup>
                        </Box>
                      }
                      {currentQuestion.type === "MSQ" &&
                        <Box m={3}>
                          <CheckboxGroup value={currentQuestionAnswer as string[]} onChange={(value) => onAnwswerClick(currentQuestion.id, value)}>
                            <Stack spacing={5}>
                              {currentQuestion.choices.map((choice: string) => (
                                <Checkbox value={choice} borderColor={'gray.600'} key={choice}>
                                  <div dangerouslySetInnerHTML={{ __html: choice }}></div>
                                </Checkbox>

                              ))}
                            </Stack>
                          </CheckboxGroup>
                        </Box>

                      }
                      {currentQuestion.type === "IB" &&
                        <Input type="text" width={'50%'} value={currentQuestionAnswer?.toString() || ''} borderColor={'gray.600'} onChange={(event: any) => onAnwswerClick(currentQuestion.id, event.target.value)} ></Input>
                      }

                    </Box>

                    <HStack gap={0}>
                      <Spacer />
                      {currentQuestionIndex > 0 && <Button colorScheme={'brand'} bgColor={"brand.900"} color={'white'} onClick={onClickPrevious}  >
                        Previous Question
                      </Button>}
                      {currentQuestionIndex !== questions.length - 1 &&
                        <Button colorScheme={'brand'} bgColor={"brand.900"} color={'white'} onClick={onClickNext} ml={5} mr={10}>
                          Next Question
                        </Button>
                      }
                      {currentQuestionIndex === questions.length - 1 && <ConfirmationModal
                        ml={5} mr={10}
                        triggerButtonText="Finish Test"
                        confirmationHeader="Finish Test"
                        colorScheme="orange"
                        confirmationBody="Are you sure want to finish test?"
                        onConfirmation={onClickNext}
                      />}

                      <Heading size={'md'} color={'brand.700'}>{currentQuestionIndex + 1}</Heading>
                      <Heading size={'md'} color={'brand.900'}>/{questions.length}</Heading>
                    </HStack>
                  </Box>
                </>
                }
              </>

            ) : (
              <Result result={result} questions={questions} />
            )}
          </>}
      </AbsoluteCenter>

    </Box >
  );
};

export default Quiz;
