export default class fetchWrapper {
    private _accessToken: string
    constructor(accessToken?: string) {
        this._accessToken = accessToken || '';
    }
    get(url: string, headers: any | null = null) {
        const requestOptions: RequestInit = {
            method: 'GET'
        };
        if (this._accessToken !== '') {
            requestOptions.headers = {
                'Authorization': `Bearer ${this._accessToken}`
            };
        }

        if (headers) {
            requestOptions.headers = {
                ...requestOptions.headers,
                ...headers
            };
        }
        
        return fetch(url, requestOptions);
    }

    post(url: string, body: any, headers: any | null = null, serialize:boolean=true) {
        const requestOptions: RequestInit = {
            method: 'POST',
            body: serialize ? JSON.stringify(body) : body
        };
        if(serialize){
            requestOptions.headers = {
                ...requestOptions.headers,
                'Content-Type': 'application/json'
            }
        }
        if (this._accessToken !== '') {
            requestOptions.headers = {
                ...requestOptions.headers,
                'Authorization': `Bearer ${this._accessToken}`
            };
        }

        if (headers) {
            requestOptions.headers = {
                ...requestOptions.headers,
                ...headers
            };
        }
        return fetch(url, requestOptions);
    }

    put(url: string, body: any) {

        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };
        if (this._accessToken !== '') {
            requestOptions.headers = {
                'Authorization': `Bearer ${this._accessToken}`
            };
        }
        return fetch(url, requestOptions);
    }

    // prefixed with underscored because delete is a reserved word in javascript
    delete(url: string) {
        const requestOptions: RequestInit = {
            method: 'DELETE'
        };
        if (this._accessToken !== '') {
            requestOptions.headers = {
                'Authorization': `Bearer ${this._accessToken}`
            };
        }
        return fetch(url, requestOptions);
    }
}