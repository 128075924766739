import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";

import {
    BrowserAuthOptions,
    Configuration,
    LogLevel,
} from "@azure/msal-browser";


export const createPublicClientConfig: () => Configuration = () => {

    const authConfig: BrowserAuthOptions = {
        clientId: window.REACT_APP_AZURE_AD_CLIENT_ID || '',
        authority: `https://login.microsoftonline.com/${window.REACT_APP_AZURE_AD_TENANT_ID || ''}`,
        navigateToLoginRequestUrl: true,
        redirectUri: '/login-redirect.html'
    };

    return {
        auth: authConfig,
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
        system: {
            loadFrameTimeout: 9000,
            loggerOptions: {
                loggerCallback: (level: LogLevel, message: any, containsPii: any) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.log(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
            },
        },
    };
};


export function authenticate(
    msalContext: IMsalContext
) {

    return new Promise<void>((resolve, reject) => {
        const { instance, inProgress, accounts } = msalContext;
        const accessTokenRequest = {
            scopes: [window.REACT_APP_AZURE_AD_SCOPE || 'User.Read'],
            account: accounts[0] || "foo@ais.com",
        };
        if (inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse: any) => {
                    resolve()
                })
                .catch((error: any) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    console.log(error)
                    reject()
                });
        }
    })
}

export async function getAccessToken(
    msalContext: IMsalContext,
    scopes: string[] = [window.REACT_APP_AZURE_AD_SCOPE || 'User.Read']
): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const { instance, inProgress, accounts } = msalContext;

        if (inProgress === InteractionStatus.None) {
            const silentTokenRequest = {
                scopes,
                account: accounts[0] || "foo@ais.com",
            };
            instance
                .acquireTokenSilent(silentTokenRequest)
                .then((accessTokenResponse: any) => {
                    resolve(accessTokenResponse.accessToken);
                })
                .catch((error: any) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        const accessTokenRequest = {
                            scopes: [window.REACT_APP_AZURE_AD_SCOPE || 'User.Read'],
                            account: accounts[0] || "foo@ais.com",
                        };
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    reject(error);
                });
        }
    });
}
