import { useState } from "react";
import { SingleDatepicker } from "../components/DatePicker";
import ParticipantsReport from "../components/ParticipantsReport";
import { Box, HStack, Heading, Link, Spacer } from "@chakra-ui/react";
import { format } from "date-fns";
import { DownloadIcon } from "@chakra-ui/icons";

const Report = () => {

    const [date, setDate] = useState(new Date())
    return <Box px={20} py={10}>
        <Heading size={'lg'} mb={3} alignContent={"center"}>Test Report</Heading>
        <HStack>
            <Spacer />
            <Heading size={"md"}>Test Date: </Heading>
            <Box width={'300px'}>
                <SingleDatepicker onDateChange={function (date: Date): void {
                    setDate(date)
                }} date={date} />
            </Box>
            <Link href={`/api/report-download/${format(date, "yyyy-MM-dd")}`} ><DownloadIcon fontSize={"24px"} /> </Link>
        </HStack>
        <ParticipantsReport testDate={date} />
    </Box>
}

export default Report;