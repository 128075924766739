import { HStack, Heading, Spacer } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";


const QuizTimer = ({ onTimeElapsed, quizTimeInMinutes }: { onTimeElapsed: () => void, quizTimeInMinutes: number }) => {

    const newDateObj = moment.utc().add(quizTimeInMinutes, 'm');
    const [remainingTime, setRemainingTime] = useState("59:99");

    const secondsToMMSS = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.trunc(seconds % 60);
      
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
      
        return `${formattedMinutes}:${formattedSeconds}`;
      }

    useEffect(() => {
        const timer = setInterval(() => {
            const now = moment.utc()
            if (newDateObj.isAfter(now)) {
                setRemainingTime(secondsToMMSS(moment.duration(newDateObj.diff(now)).asSeconds()))
            } else {
                onTimeElapsed();
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return <HStack><Spacer /><Heading size={'xs'}>You have: {remainingTime}</Heading></HStack>
}

export default QuizTimer;