import { Box, AbsoluteCenter, Button, Input, Heading, useToast, Spinner } from "@chakra-ui/react"
import { useForm } from 'react-hook-form'
import {
    FormErrorMessage,
    FormLabel,
    FormControl
} from '@chakra-ui/react'
import fetchWrapper from "../lib/fetch-wrapper"
import { useState } from "react"


const Login = ({ onLoginSuccess }: { onLoginSuccess: (authInfo: any, quizInfo: any) => void }) => {

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm()
    const toast = useToast();
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    function onSubmit(values: any) {
        setIsAuthenticating(true)
        const fw = new fetchWrapper();
        fw.get(`/api/questions/${values.testCode}`).then((questionsRes: any) => {
            if (questionsRes.status === 200) {
                questionsRes.json().then((questionsJson: any) => {
                    onLoginSuccess(values, questionsJson)
                })
            } else if (questionsRes.status === 404) {
                toast({
                    title: 'Test not found',
                    description: 'Given test code not found. check with your administrator.',
                    status: "warning",
                    position: 'top',
                    isClosable: true,
                })
            }
            else {
                toast({
                    title: 'Error occurred',
                    description: 'Something went worng. Try again...',
                    status: "error",
                    position: 'top',
                    isClosable: true,
                })

            }
            setIsAuthenticating(false)
        }).catch((execption: any) => {
            console.log(execption)
            setIsAuthenticating(false)
        })
    }

    return <>
        <Box color={'black'} p={10}>

            <AbsoluteCenter axis={'both'}>
                {isAuthenticating && <Spinner color={'brand.900'} size='xl' />}
                {!isAuthenticating &&
                    <Box minW={'500px'}>
                        <Heading size={'lg'}> Sign Up</Heading>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={Boolean(errors.name)} mt={5}>
                                <FormLabel htmlFor='name'>Full Name</FormLabel>
                                <Input
                                    id='name'
                                    placeholder='Full Name'
                                    {...register('name', {
                                        required: 'This is required'
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors?.name && errors?.name?.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={Boolean(errors.email)} mt={5}>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <Input
                                    id='email'
                                    placeholder='Email'
                                    {...register('email', {
                                        required: 'This is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors?.email && errors?.email?.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={Boolean(errors.testCode)} mt={5}>
                                <FormLabel htmlFor='name'>Test Code</FormLabel>
                                <Input
                                    id='testCode'
                                    placeholder='Test Code'
                                    {...register('testCode', {
                                        required: 'This is required'
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors?.testCode && errors?.testCode?.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                            <Button mt={4} colorScheme='green' isLoading={isSubmitting} type='submit'>
                                Sign Up
                            </Button>
                        </form>
                    </Box>
                }

            </AbsoluteCenter>
        </Box>

    </>
}

export default Login;