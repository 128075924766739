import React from "react";
import ReactDOM from "react-dom/client";
import Index from "./pages/Index";
import NoPage from "./pages/NoPage";
import Layout from "./components/Layout";
import { Providers } from "./components/Provider";
import { Routes, Route, HashRouter } from "react-router-dom";
import Report from "./pages/Report";
import SecurePage from "./pages/SecurePage";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { createPublicClientConfig } from "./lib/auth";
import HOL from "./pages/HOL";


export default function App() {
    
    const msalInstance = new PublicClientApplication(createPublicClientConfig());

    return (
        <MsalProvider instance={msalInstance}>
            <Providers>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Index />} />
                            <Route path="report" element={<SecurePage element={<Report />} />} />
                            <Route path="hol" element={<HOL />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </HashRouter>
            </Providers>
        </MsalProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
