import { Box, TableContainer, Table, Tr, Th, Td, Tbody, TableCaption, Text, Highlight, Heading } from "@chakra-ui/react"
import MarkdownViewer from "./MarkdownViewer"

const Instructions = ({ name, quizId, description, numberOfQuestions, duration }
    : { name: string, quizId: string, description: string, numberOfQuestions: number, duration: number }) => {
    return <Box color={'black'}>
        <Text ml={5}>
            Dear <span style={{ fontWeight: "bold", textTransform: 'uppercase' }}>{name}</span>, Please read the following before start test.
        </Text>
        <TableContainer my={5} minW={'1000px'} >
            <Table size={'md'} variant={'simple'} colorScheme="blue">

                <Tbody>
                    <Tr>
                        <Th>Test Code</Th>
                        <Td >{quizId}</Td>
                    </Tr>
                    <Tr>
                        <Th>Description</Th>
                        <Td><MarkdownViewer content={description} ></MarkdownViewer></Td>
                    </Tr>
                    <Tr>
                        <Th>Total No. of Questions</Th>
                        <Td >{numberOfQuestions}</Td>
                    </Tr>
                    <Tr>
                        <Th>Duration in Minutes</Th>
                        <Td>{duration}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    </Box>
}

export default Instructions;