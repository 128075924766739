import {
    Box, Spinner, TableContainer, Table, Tr, Th, Td, Tbody, TableCaption, Thead, useToast,
    HStack,
    Text,
    Spacer,
    Link,
    Heading
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import fetchWrapper from "../lib/fetch-wrapper";
import moment from "moment";
import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from "@chakra-ui/icons";


const AttemptedQuestionsTable = ({ attemptedQuestions, inProgress }: { attemptedQuestions: any[], inProgress: boolean }) => {

    return <>
        {inProgress && <Spinner size={'lg'} />}
        {!inProgress &&
            <TableContainer overflowX="unset" overflowY="unset">
                <Table size={'sm'} variant={"simple"}>
                    <TableCaption placement="top">Attempted Questions</TableCaption>
                    <Thead bgColor={'gray.100'}>
                        <Tr bgColor={"gray.300"}>
                            <Th>Questions</Th>
                            <Th>Is Correct</Th>
                            <Th>Answer</Th>
                            <Th>Correct Answer</Th>
                            <Th>Choice 1</Th>
                            <Th>Choice 2</Th>
                            <Th>Choice 3</Th>
                            <Th>Choice 4</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {attemptedQuestions.map(
                            (info: any) => {
                                return (
                                    <>
                                        <Tr>
                                            <Td>{info.question}</Td>
                                            <Td>{info.answer === info.correctAnswer ? "Yes" : "No"}</Td>
                                            <Td>{info.answer}</Td>
                                            <Td>{info.correctAnswer}</Td>
                                            {
                                                info.choices?.map((choice: any) => {
                                                    return <Td>{choice}</Td>
                                                })
                                            }
                                            {info.choices && info.choices.length < 4 && <Td colSpan={4 - info.choices.length}></Td>}
                                            {!info.choices && <Td colSpan={4} />}
                                        </Tr>
                                    </>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        }
    </>
}
const DisplayData = ({ jsonData }: { jsonData: any[] }) => {
    const [selectedRow, setSelectedRow] = useState<string>("")
    const [attemptedQuestions, setAttemptedQuestions] = useState<any[]>([])
    const [inProgress, setInProgress] = useState(false)
    const toast = useToast();




    useEffect(() => {
        setAttemptedQuestions([])
        if (selectedRow !== '') {
            setInProgress(true)
            const fw = new fetchWrapper();
            fw.get(`/api/attempts/${selectedRow}`).then((resultsResp: any) => {
                if (resultsResp.status === 200) {
                    resultsResp.json().then((resultsJson: any) => {
                        setAttemptedQuestions(resultsJson)

                    })
                } else {
                    toast({
                        title: 'Error occurred',
                        description: 'Something went worng. Try again...',
                        status: "error",
                        position: 'top',
                        isClosable: true,
                    })
                }
                setInProgress(false)
            }).catch((execption: any) => {
                console.log(execption)
                setInProgress(false)
            });
        }

    }, [selectedRow])
    return <>
        {jsonData.map(
            (info) => {
                return (
                    <>
                        <Tr key={info.RowKey}>
                            <Td>
                                {selectedRow === info.RowKey && <ChevronUpIcon onClick={() => setSelectedRow("")}></ChevronUpIcon>}
                                {selectedRow !== info.RowKey && <ChevronDownIcon onClick={() => setSelectedRow(info.RowKey)} ></ChevronDownIcon>}
                            </Td>
                            <Td>{info.Name}</Td>
                            <Td>{info.Email}</Td>
                            <Td>{info.TestCode}</Td>
                            {/* <Td>{info.Score}</Td> */}
                            <Td>{info.TotalQuestions}</Td>
                            <Td>{info.WriteAnswers}</Td>
                            <Td>{info.WrongAnswers}</Td>
                            <Td>{info.NotAttempted}</Td>
                            <Td>{moment(moment.utc(info.TimeSubmitted).toDate()).local().format('YYYY-MM-DD HH:mm:ss')}</Td>
                        </Tr>
                        {selectedRow === info.RowKey && attemptedQuestions && <Tr key={`${info.RowKey}-sub`}>
                            <Td />
                            <Td colSpan={8} bgColor={'gray.100'}>
                                <AttemptedQuestionsTable inProgress={inProgress} attemptedQuestions={attemptedQuestions} />
                            </Td>
                        </Tr>}
                    </>
                )
            })
        }
    </>
}

const ParticipantsReport = ({ testDate }: { testDate: Date }) => {
    const [reportFetchIsInProgress, setReportFetchIsInProgress] = useState(true)
    const [testResults, setTestResuts] = useState<any[] | null>(null);

    const toast = useToast();
    useEffect(() => {
        setReportFetchIsInProgress(true)
        const fw = new fetchWrapper();
        fw.get(`/api/report/${format(testDate, "yyyy-MM-dd")}`).then((resultsResp: any) => {
            if (resultsResp.status === 200) {
                resultsResp.json().then((resultsJson: any) => {
                    setTestResuts(resultsJson)

                })
            } else {
                toast({
                    title: 'Error occurred',
                    description: 'Something went worng. Try again...',
                    status: "error",
                    position: 'top',
                    isClosable: true,
                })
            }
            setReportFetchIsInProgress(false)
        }).catch((execption: any) => {
            console.log(execption)
            setReportFetchIsInProgress(false)
        });

    }, [testDate])
    return <>
        {reportFetchIsInProgress && <Spinner size={'lg'} />}
        {!reportFetchIsInProgress && testResults && <Box maxH={'calc(100vh - 375px)'} overflowY={'auto'} >
            <TableContainer overflowX="unset" overflowY="unset">
                <Table size={'sm'}>
                    <TableCaption placement="top">
                        <Heading size={"md"} mb={5}>Participants Test Report on  {format(testDate, "yyyy-MM-dd")}</Heading>
                    </TableCaption>
                    <Thead position="sticky" top={0} zIndex="1" py={10} bgColor={'brand.200'}>
                        <Tr>
                            <Th bgColor={"brand.900"} color={"white"}></Th>
                            <Th bgColor={"brand.900"} color={"white"}>Name</Th>
                            <Th bgColor={"brand.900"} color={"white"}>Email</Th>
                            <Th bgColor={"brand.900"} color={"white"}>Test Code</Th>
                            {/* <Th bgColor={"brand.900"} color={"white"}>Score</Th> */}
                            <Th bgColor={"brand.900"} color={"white"}>Total Questions</Th>
                            <Th bgColor={"brand.900"} color={"white"}>Write Answers</Th>
                            <Th bgColor={"brand.900"} color={"white"}>Wrong Answers</Th>
                            <Th bgColor={"brand.900"} color={"white"}>Not Attempted</Th>
                            <Th bgColor={"brand.900"} color={"white"}>Submitted Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {testResults?.length > 0 &&
                            <DisplayData jsonData={testResults} />
                        }
                        {testResults?.length <= 0 && <Tr><Td colSpan={9} ><Text alignContent={"center"} fontWeight={500} fontSize={"18px"}>No records found</Text></Td></Tr>}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>}
    </>
}

export default ParticipantsReport;