import { AbsoluteCenter, Box, Heading } from "@chakra-ui/react";

function NoPage() {
    return <Box position='relative' w={'100vw'} h='100vh'>
    <AbsoluteCenter p='4' axis='both'>
      <Heading size={'md'}>404 Not found.</Heading>
    </AbsoluteCenter>
  </Box>
}

export default NoPage;